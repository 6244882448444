import '@styles/main.scss';
// import SwiperLoader from './utils/SwiperLoader';

document.addEventListener('DOMContentLoaded', () => {
   window.sseaSwiper = {
      initSlider(swiperElement, swiperConfig) {
         if ('undefined' === typeof Swiper) {
            const asyncSwiper = elementorFrontend.utils.swiper;

            new asyncSwiper(swiperElement, swiperConfig).then((newSwiperInstance) => {
               const mySwiper = newSwiperInstance;
               return mySwiper;
            });
         } else {
            const mySwiper = new Swiper(swiperElement, swiperConfig);
            return mySwiper;
         }
      },
   };
});
